function tempAlert(title, text) {
	var pAlert = document.createElement('div');
	pAlert.classList.add('notify__success');
	
	var pTitle = document.createElement('h4');
	pTitle.innerHTML = title;
	pAlert.appendChild(pTitle);

	var pText = document.createElement('div');
	pText.innerHTML = text;
	pAlert.appendChild(pText);

	document.body.appendChild(pAlert);
	
	setTimeout(function() {
		pAlert.remove();
	}, 2000);
}

function copyLink(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    tempAlert("Link copied ✓", "You can paste it any where");
    document.body.removeChild(el);
}

function alphanumeric(string) { 
    var letters = /^[0-9a-z_]+$/;
    if(!string.match(letters)) {
        tempAlert('⚠️ Warning','Numbers, lowercase alphabets and underscore only');
    }
}

function slugify(text) {
	// const regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"\s]/gi;
	// removed '-' from above
	// const regExp = /[\{\}\[\]\/?.,;:|\)*~`’‘!^+<>@\#$%&\\\=\(\'\"\s]/gi;

	return text.toString().toLowerCase()
		.replace(/\s+/g, '-')           // Replace spaces with -
		.replace(/[^\w\-]+/g, '')       // Remove all non-word chars
		// .replace(regExp, "")			// Remove special chars
		.replace(/\-\-+/g, '-')         // Replace multiple - with single -
		.trim();            // Trim - from end of text
}

function setTimeAgo(target) {
	var thisTimer;
	timeAgo();
	function timeAgo() {
	
			var templates = {
					prefix: "",
					suffix: " ago",
					seconds: "less than a minute",
					minute: "about a minute",
					minutes: "%d minutes",
					hour: "about an hour",
					hours: "about %d hours",
					day: "a day",
					days: "%d days",
					month: "about a month",
					months: "%d months",
					year: "about a year",
					years: "%d years"
			};
			
			var template = function(t, n) {
					return templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));
			};
	
			var timer = function(time) {
					if (!time)
							return;
					time = time.replace(/\.\d+/, ""); // remove milliseconds
					time = time.replace(/-/, "/").replace(/-/, "/");
					time = time.replace(/T/, " ").replace(/Z/, " UTC");
					time = time.replace(/([\+\-]\d\d)\:?(\d\d)/, " $1$2"); // -04:00 -> -0400
					time = new Date(time * 1000 || time);
	
					var now = new Date();
					var seconds = ((now.getTime() - time) * .001) >> 0;
					var minutes = seconds / 60;
					var hours = minutes / 60;
					var days = hours / 24;
					var years = days / 365;
	
					return 'last saved : '+templates.prefix + (
									seconds < 45 && template('seconds', seconds) ||
									seconds < 90 && template('minute', 1) ||
									minutes < 45 && template('minutes', minutes) ||
									minutes < 90 && template('hour', 1) ||
									hours < 24 && template('hours', hours) ||
									hours < 42 && template('day', 1) ||
									days < 30 && template('days', days) ||
									days < 45 && template('month', 1) ||
									days < 365 && template('months', days / 30) ||
									years < 1.5 && template('year', 1) ||
									template('years', years)
									) + templates.suffix;
			};
	
			var el = document.querySelector(target);
			if (el) {
					const saved = el.dataset.saved;
					if (saved === 'true') {
							clearTimeout(thisTimer);
							el.innerHTML = 'Saved';
					}
					else {
							el.innerHTML = timer(el.getAttribute('title') || el.getAttribute('datetime'));
							// update time every minute
							thisTimer = setTimeout(timeAgo, 60000);
					}
			}
			else {
					clearTimeout(thisTimer);
			}
	}
}


function usernamefy(text) {
	return text.toString().toLowerCase()
		.replace(/\s+/g, '')           // Replace spaces with -
		.replace(/[^\w_]+/g, '')        // Remove all non-word chars
		.trim();            // Trim - from end of text
}

function dataImageChecker(string) {
    var value = string;
    if (value.includes('data:image/jpeg;base64')) {
      return true;
    }

    return false;
}

function httpChecker(string) {
	var value = string;
	if (value) {
		if (!value.includes('http://') && !value.includes('https://')) {
		  value = 'http://'+value;
		}
	}

    return value;
}

function firebaseChecker() {
    const str = window.location.hostname;
    if (str.includes("firebaseapp.com")) {
        window.location.replace("https://paper.coffee");
    }
}

export { tempAlert, copyLink, alphanumeric, slugify, setTimeAgo, usernamefy, dataImageChecker, httpChecker, firebaseChecker }