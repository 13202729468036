import React from 'react';

function httpChecker(string) {
    var value = string;
    if (value) {
        if (!value.includes('http://') && !value.includes('https://') && !value.includes('data:image')) {
          value = 'http://'+value;
        }
    }

    return value;
}

const QuickLink = (props) => {
    const { value } = props;
  
    var fontawesome = "";

    if (value.includes("twitter.com")) {
        fontawesome = "fab fa-twitter";
    }
    else if (value.includes("facebook.com")) {
        fontawesome = "fab fa-facebook-f";
    }
    else if (value.includes("instagram.com")) {
        fontawesome = "fab fa-instagram";
    }
    else if (value.includes("vk.com")) {
        fontawesome = "fab fa-vk";
    }
    else if (value.includes("linkedin.com")) {
        fontawesome = "fab fa-linkedin-in";
    }
    else if (value.includes("medium.com")) {
        fontawesome = "fab fa-medium-m";
    }
    else if (value.includes("wordpress.com")) {
        fontawesome = "fab fa-wordpress";
    }
    else if (value.includes("behance.com")) {
        fontawesome = "fab fa-behance";
    }
    else if (value.includes("dribbble.com")) {
        fontawesome = "fab fa-dribbble";
    }
    else if (value.includes("github.com")) {
        fontawesome = "fab fa-github";
    }
    else if (value.includes("youtube.com")) {
        fontawesome = "fab fa-youtube";
    }
    else if (value.includes("twitch.com")) {
        fontawesome = "fab fa-twitch";
    }
    else if (value.includes("vimeo.com")) {
        fontawesome = "fab fa-vimeo-v";
    }
    else if (value.includes("spotify.com")) {
        fontawesome = "fab fa-spotify";
    }
    else if (value.includes("soundcloud.com")) {
        fontawesome = "fab fa-soundcloud";
    }
    else if (value.includes("kickstarter.com")) {
        fontawesome = "fab fa-kickstarter-k";
    }
    else if (value.includes("snapchat.com")) {
        fontawesome = "fab fa-snapchat-ghost";
    }
    else if (value.includes("producthunt.com")) {
        fontawesome = "fab fa-product-hunt";
    }
    else if (value.includes("//t.me/")) {
        fontawesome = "fab fa-telegram-plane";
    }
    else if (value.includes("whatsapp.com")) {
        fontawesome = "fab fa-whatsapp";
    }
    else {
        fontawesome = "fas fa-link";
    }

    var refTail = "?ref=papercoffee";

    if (value.includes("whatsapp.com")) {
        refTail = "";
    }

    return(
        <div>
            <a href={httpChecker(value)+refTail} target="_blank">
                <i className={fontawesome}></i>
            </a>
        </div>
    );
}

export default QuickLink;