import React from 'react';

const NoMatch = (props) => {

    return (
        <div className={"theme theme__coffee"}>
            <nav className="navbar fixed-top">
                <a className="navbar-brand mr-auto" href="/">🅿 Paper</a>
            </nav>
            <div className="container pb-5">
                <div className="paper__image">
                    <img src={"/assets/blank.jpg"}/>
                </div>
                <div className="paper__title mb-3">Nothing is written here...</div>
            </div>
        </div>
    )
}

export default NoMatch;