import React from 'react';

import {Editor, EditorState, convertFromRaw} from 'draft-js';

import { themeDecoder, timestampToDateString } from './themes';
import { Link } from 'react-router-dom';

const Post = (props) => {

    if (props.post) {
        var saved = props.post;
        const prev = props.prev;
        const next = props.next;
        const info = props.info;
        var html;
    
        if (saved.content) {
            // let editorState
            html = EditorState.createWithContent(convertFromRaw(JSON.parse(saved.content)));
            // let content = editorState.getCurrentContent();
    
            // maybe also should consider readonly draft.js or html-to-react kinda plugin
            // html = {__html: stateToHTML(content)};
        }

        var image = ''; 
         
        if (props.preview) {
            image = saved.cover;
        }
        else if (saved.pubImage) {
            image = "http://paper-coffee.imgix.net/"+props.userKey+"/"+saved.pubImage;
        }
        else if (!saved.cover.includes('data:image/jpeg;base64')) {
            image = saved.cover;
        }
        
        let name = info.title;
        let username = info.username;
        let updated = saved.updated;
        let pubdate = saved.pubdate;
        let author = info.author;
        let desc = info.desc;
        let title = saved.title;
        let theme = themeDecoder(info.theme);
    
        return (
            <div className={"theme "+theme}>
            <nav className="navbar fixed-top">
                {props.preview ?
                    <Link className="btn btn-sm border-0" to="/me"><i className="fas fa-arrow-left"></i></Link>
                :null}
                <a className="navbar-brand mr-auto" href="#">🅿 Paper</a>
                {props.preview ?
                    <span className="nav-item paper__name">{name}</span>
                :
                    <a href={"/@"+username} className="nav-item paper__name">{name}</a>
                }
            </nav>
            <div className="container pb-5">
                {image ? 
                <div className="paper__image mb-3">
                    <img src={image}/>
                </div>
                :null}
                <div className="paper__date pt-3">
                    {saved.publish ? <i className="fas fa-globe mr-2"></i> : <i className="fas fa-lock mr-2"></i>}
                    {pubdate? timestampToDateString(pubdate) : timestampToDateString(updated)}
                </div>
                <div className="paper__title py-3">{title}</div>
                <div className="paper__bar"></div>
                <div className="paper__content pt-3 pb-5">
                    <Editor readOnly={true} editorState={html}/>
                    <div className="mt-3 paper__author">by {author}</div>
                    {desc ? <div className="mt-1 paper__desc">{desc}</div>:null}
                </div>
                {props.preview ? null :
                    <nav className="nav nav-bottom">
                        <a className={prev ? "nav-item" : "nav-item disabled"} href={prev ? "/@"+username+"/"+prev : "#"} target="_self" disabled={!prev}><i className="fas fa-arrow-left"></i></a>
                        <a className="nav-item mx-auto" href="/me" target="_self"><i className="fas fa-pen-nib"></i></a>
                        <a className={next ? "nav-item" : "nav-item disabled"} href={next ? "/@"+username+"/"+next : "#"} target="_self" disabled={!next}><i className="fas fa-arrow-right"></i></a>
                    </nav>
                }
            </div>
            </div>
        );
    }
}

export default Post;