import React, { Component } from 'react';
import { themeDecoder, timestampToDateString } from './themes';
import QuickLink from './quickLink';

class Paper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            info: null,
            posts: null,
            page: 1
        }
        this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
        const page = this.state.page+1;
        this.setState({page: parseInt(page)});
    }

    initData() {
        if (this.props.info && this.props.posts && !this.state.info) {
            // const paper       = this.props.paper;

            this.setState({
                loading: false,
                info: this.props.info,
                posts: this.props.posts,
                page: 1
            });
        }
    }

    componentWillMount() {
        this.initData();
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="loading align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            );
        }

        let theme = themeDecoder(this.state.info.theme);
        let username = this.state.info.username;

        return (
            <div className={"theme "+theme}>
                <nav className="navbar fixed-top">
                    <a className="navbar-brand mr-3" href="/">🅿 Paper</a>
                </nav>
                <div className="container">
                    <div className="paper__date pt-3">{this.state.info.author}</div>
                    <div className="paper__title py-3">{this.state.info.title}</div>
                    <div className="paper__content pb-3">{this.state.info.desc}</div>
                    {this.state.info.quicklinks ? 
                        <div className="paper__quickLinks pb-3">
                        {this.state.info.quicklinks.map(
                            (item, i) => {
                                if (item) return <QuickLink key={i} value={item}/>
                            }
                        )}
                        </div>
                    :null}
                    <div className="pt-3">
                        {this.state.posts ? this.state.posts.map(
                            (item, i) => {
                                if (i < this.state.page*10) return (
                                    <div className="list-item">
                                        <a href={"/@"+username+"/"+item.slug} key={i} className="list-item-title text-truncate col">{item.title}</a>
                                        <div className="list-item-buttons mt-2">
                                            <span className="list-item-pub"><i className="fas fa-globe mr-2"></i>{timestampToDateString(item.pubdate)}</span>
                                        </div>
                                    </div>
                                );
                            }
                        ):null}
                        {this.state.posts && this.state.posts.length > this.state.page*10 ?
                            <button type="button" className="btn btn-sm btn-block" onClick={this.loadMore}>Load More</button>
                        :null}
                    </div>
                </div>
            </div>
        );
    }
}

export default Paper;