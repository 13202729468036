// src/client.js
import * as firebase from 'firebase';

// Initialize Firebase
var config = {
    apiKey: "AIzaSyDAoFEiJCNHjBFiscIb2yTNY6d-JkXKhzw",
    authDomain: "paper-coffee.firebaseapp.com",
    databaseURL: "https://paper-coffee.firebaseio.com",
    projectId: "paper-coffee",
    storageBucket: "paper-coffee.appspot.com",
    messagingSenderId: "289800150030"
};
const fire = firebase.initializeApp(config);
const db = firebase.database();
const auth = firebase.auth();
const str   = firebase.storage();

const googleProvider = new firebase.auth.GoogleAuthProvider();

function googleSignin() {
    auth.signInWithPopup(googleProvider).then(function(result) {
        // update user info to db
        saveUser(result.user);
        return result.user;
    }).catch(function(error) {
        console.log(error.code + ' : ' + error.message);
        return null;
    });
}

function saveUser(user) {
    var updates = {};
    updates['name'] 		= user.displayName;
    updates['email'] 		= user.email;
    // if (user.photoURL) {
    //   updates['image'] 	= user.photoURL;
    // }
    
    db.ref('/users/'+user.uid+'/info').update(updates);
}

function userSignout() {
    auth.signOut().then(function() {
      return true;
    }).catch(function(error) {
      console.log(error.code + ' : ' + error.message);
      return false;
    });
}

function errorLog(action, error) {
    var updates = {};
    if (auth.currentUser) {
        updates['user'] = auth.currentUser.uid;
    }
    updates['action'] = action;
    updates['error'] = error;
    updates['timestamp'] = new Date().getTime();
    const logs = db.ref('/errors/');
    const key = logs.push().key;
    logs.child(key).update(updates);
}

export { fire, db, str, auth, googleSignin, userSignout, errorLog }