import React, { Component } from 'react';
var Airtable = require('airtable');
var base = new Airtable({apiKey: 'keyS39hQGJUX8CIOz'}).base('appEgItyzrH8vZvah');

class AppTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pw: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillMount() {
        var data = [];
        base('Table 1').select({
            view: 'Grid view'
        }).firstPage(function(err, records) {
            if (err) { console.error(err); return; }
            
            records.forEach(function(record) {
                // console.log('Retrieved', record.get('pw'));
                data[record.get('pw')] = record.get('value');
            });
        });
        // console.log(data);
        this.setState({data: data});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }    

    handleSubmit(event) {
        if (this.state.data[this.state.pw]) {
            alert('뾰로롱 당신의 학점은 ' + this.state.data[this.state.pw]);
        }
        else {
            alert('잘못된 비밀번호 입니다');
        }
        event.preventDefault();
    }

    render() {
        return (
            <div>
                <form id="form" onSubmit={this.handleSubmit}>
                    <input type="text" id="pw" name="pw" value={this.state.pw} onChange={this.handleChange} />
                    <input type="submit" value="확인" />
                </form>
                <div id="result"></div>
            </div>
        );
    }
}

export default AppTest;