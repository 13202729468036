import React, { Component } from 'react';
import { fire, str, db, auth, errorLog } from './fire';
import {Editor, EditorState, RichUtils, getDefaultKeyBinding, KeyBindingUtil, convertToRaw, convertFromRaw} from 'draft-js';
import {
    Link
} from 'react-router-dom';
import { tempAlert, copyLink, alphanumeric, slugify, setTimeAgo, dataImageChecker } from './helper';
import { themeDecoder } from './themes';
import Jimp from 'jimp';

const { hasCommandModifier, isOptionKeyCommand } = KeyBindingUtil;

var t;

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: '',
            title: '',
            // tags: '',
            cover: '',
            pubImage: '',
            updated: '',
            pubdate: '',
            slug: '',
            publish: false,
            editorState: EditorState.createEmpty(),
            saved: true,
            preview: '',
            tempCover: ''
        };
        this.changePublish = this.changePublish.bind(this);
        this.onChange = (editorState) => this.setState({editorState: editorState, saved: false});
        this.handleChange   = this.handleChange.bind(this);
        this.handleSubmit   = this.handleSubmit.bind(this);
        this.shortSave      = this.shortSave.bind(this);
        this.handleKeyCommand = this.handleKeyCommand.bind(this);

        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
        this.focus = () => {
            this.refs.editor.focus();
            this.handleStyleControls(true);
        }
        this.onBlur = () => this.handleStyleControls(false);
        this.updateFile = this.updateFile.bind(this);

        // this.handlePastedText = (text, styles, editorState) => {
        //     this.setState({
        //       editorState: removeEditorStyles(text, editorState)
        //     });
        // }
    }

    handleKeyCommand(command, editorState) {
        var newState;
        if (command === 'header-one' || command === 'header-two' || command === 'header-three' || command === 'header-four' || command === 'header-five' || command === 'header-six' ) {
            newState = RichUtils.toggleBlockType(this.state.editorState, command)
        }
        else {
            newState = RichUtils.handleKeyCommand(this.state.editorState, command);
        }
        if (newState) {
            this.onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    _mapKeyToEditorCommand(e) {
        // console.log(e.altKey && e.metaKey && e.keyCode === 49);
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        // changed to save it globally
        // ------
        // if (e.key === 's' && hasCommandModifier(e)) {
        //     // return 'editor-save';
        //     this.handleSubmit(e);
        //     return;
        // }
        if (e.keyCode === 49 && hasCommandModifier(e)) {
            this._handleKeyCommand('header-one', false);
            e.preventDefault();
            return;
        }
        if (e.keyCode === 50 && hasCommandModifier(e)) {
            this.handleKeyCommand('header-two', false);
            e.preventDefault();
            return;
        }
        if (e.keyCode === 51 && hasCommandModifier(e)) {
            this.handleKeyCommand('header-three', false);
            e.preventDefault();
            return;
        }
        if (e.keyCode === 52 && hasCommandModifier(e)) {
            this.handleKeyCommand('header-four', false);
            e.preventDefault();
            return;
        }
        if (e.keyCode === 53 && hasCommandModifier(e)) {
            this.handleKeyCommand('header-five', false);
            e.preventDefault();
            return;
        }
        if (e.keyCode === 54 && hasCommandModifier(e)) {
            this.handleKeyCommand('header-six', false);
            e.preventDefault();
            return;
        }
        return getDefaultKeyBinding(e);
    }
    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }
    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    componentDidMount() {
        this.initData();
        document.addEventListener("keydown", this.shortSave, false);
    }

    componentDidUpdate() {
        this.initData();
        setTimeAgo("#"+this.state.key+"timeago");

        clearTimeout(t);
        if (!this.state.saved) {
            t = setTimeout(
                function() {
                    this.handleSubmit();
                }
                .bind(this),
                5000
            );
        }
    }

    componentWillUnmount(){
        clearTimeout(t);
        document.removeEventListener("keydown", this.shortSave, false);
    }

    shortSave(event) {
        if((event.key === 'Control' || event.metaKey) && event.key == 's') {
            event.preventDefault();
            this.handleSubmit();
        }
    }

    initData() {
        if (this.props.post && this.state.key === '') {
            var saved       = this.props.post;
            var editorState = EditorState.createEmpty();

            if (saved.content) {
                editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(saved.content)));
            }

            this.setState({
                key: saved.key,
                title: saved.title,
                slug: saved.slug,
                // tags: saved.tags ? saved.tags : '',
                cover: saved.cover,
                pubImage: saved.pubImage,
                updated: saved.updated,
                pubdate: saved.pubdate,
                publish: saved.publish,
                editorState: editorState,
                tempCover: saved.cover
            });
        }
    }
    
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (name === 'title') {
            const slug = slugify(value)+this.state.key;

            this.setState({
                [name]: value,
                slug: slug,
                saved: false
            });
        }
        else {
            this.setState({
                [name]: value,
                saved: false
            });
        }

    }

    changePublish() {
        if (!this.state.title) {
            tempAlert("⚠️ Oops", "Title is required");
            return;
        }

        const posts = db.ref('/papers/'+auth.currentUser.uid+'/posts');
        
        var updates = Object.assign({}, this.state);
        delete updates.key;
        delete updates.editorState;
        delete updates.saved;
        delete updates.preview;
        delete updates.tempCover;

        // update cover value only if there is a change
        if (this.state.tempCover === this.state.cover) {
            delete updates.cover;
        }

        const contentState = this.state.editorState.getCurrentContent();
        updates['content'] = JSON.stringify(convertToRaw(contentState));
        
        var key;
        
        if (this.state.key) {
            key = this.state.key;
        }
        else {
            key = posts.push().key;
        }

        const updated = new Date().getTime();

        updates['updated'] = updated;

        // pub related
        updates['publish'] = !this.state.publish;
        var pubStatus = !this.state.publish;

        if (pubStatus) {
            this.pubImage();
            updates['pubdate'] = updated;
        }
        else {
            let date = updates['pubdate'];
            this.unpubImage();
            updates['pubdate'] = '';
        }
        
        posts.child(key).update(updates).then(function(){
            if (this.state.key === '') {
                this.setState({key: key, updated: updated, tempCover: this.state.cover, saved: true});
            }
            else {
                this.setState({updated: updated, publish: !this.state.publish, pubdate: updates['pubdate'], tempCover: this.state.cover, saved: true});
            }
            if (pubStatus) {
                tempAlert("🌏 Published!", "Share this deck with the link");
            }
            else {
                tempAlert("😎 Unpublished", "Now it's hidden from the world");
            }
        }.bind(this)).catch(function(error) {
            errorLog("changePublish", error);
            tempAlert("⚠️ Failed", "Error occured while saving");
        });
    }

    // 이미지 프리뷰 변경
    updateFile() {
        const file = document.querySelector("#paper__file").files[0];
        if (file) {
            const path = URL.createObjectURL(file);
            Jimp.read(path, (err, img) => {
                if (err) throw err;
                img
                  .resize(700, Jimp.AUTO) // resize
                  .quality(60) // set JPEG quality
                  .greyscale() // set greyscale
                  .getBuffer(Jimp.MIME_JPEG, (err, buffer)=>{
                    //Transfer image file buffer to base64 string
                    let base64Image = buffer.toString('base64');
                    let imgSrcString = "data:" + Jimp.MIME_JPEG + ';base64,' + base64Image;
                    
                    this.setState({cover: imgSrcString, saved: false});
                  }); 
            });
        }
    }

    pubImage() {
        if (this.state.cover) {
            const result = dataImageChecker(this.state.cover);

            if (result) {
                const date = new Date().getTime();

                const fname = this.state.key + '-' + date + '.jpg';

                const path = '/public/'+auth.currentUser.uid+'/'+fname;

                // var data_fname  = null;
                str.ref(path).putString(this.state.cover, 'data_url').then((snapshot) => {
                    this.setState({pubImage: fname});
                    this.handleSubmit();
                }).catch((error) => {
                    errorLog("pubImage", error);
                    // console.log(error);
                });
                // const upload = 
                // upload.then((snapshot) => {
                //     const url = snapshot.ref.getDownloadURL().then(function(url) {
                //         // 기존 파일 삭제
                //         if (data_fname && data_fname != '') {
                //             console.log('delete');
                //             str.ref('/'+type+'s/'+data_fname).delete();
                //         }

                //         if(url && type == 'image') {
                //             updates['/fname/'] = fname;
                //             updates['/image/'] = url;
                //         }
                //         else {
                //             updates['/fname2/'] = fname;
                //             updates['/attach/'] = url;
                //         }

                //         if (type == 'image' && document.querySelector("#form_data_attach_file").files.length > 0) {
                //             const file = document.querySelector("#form_data_attach_file").files[0];
                //             this.storeUpdate('file', file, key, updates);
                //         }
                //         else {
                //             this.dataUpdate(key, updates);
                //         }
                //     }.bind(this)).catch(function(error) {
                //         alert(error);
                //     });
                // }).catch((error) => {
                //     alert(error);
                // });
                
            }
        }
    }

    unpubImage() {
        if (this.state.pubImage) {
            str.ref('/public/'+auth.currentUser.uid+'/'+this.state.pubImage).delete().then((snapshot) => {
                this.setState({pubImage: ''});
                this.handleSubmit();
            }).catch((error) => {
                errorLog("unpubImage", error);
                // console.log(error);
            });
        }
    }
    
    handleSubmit() {
        if (!this.state.title) {
            tempAlert("⚠️ Oops", "Title is required");
            return;
        }

        const posts = db.ref('/papers/'+auth.currentUser.uid+'/posts');
        
        var updates = Object.assign({}, this.state);
        delete updates.key;
        delete updates.editorState;
        delete updates.saved;
        delete updates.preview;
        delete updates.tempCover;

        // update cover value only if there is a change
        if (this.state.tempCover === this.state.cover) {
            delete updates.cover;
        }
        else if (this.state.publish && this.state.tempCover !== this.state.cover) {
            this.unpubImage();
            this.pubImage();
        }

        const contentState = this.state.editorState.getCurrentContent();
        updates['content'] = JSON.stringify(convertToRaw(contentState));
        
        var key;
        
        if (this.state.key) {
            key = this.state.key;
        }
        else {
            key = posts.push().key;
        }

        const updated = new Date().getTime();

        updates['updated'] = updated;

        console.log(key);
        console.log(updates);
        
        posts.child(key).update(updates).then(function(){
            if (this.state.key === '') {
                this.setState({key: key, updated: updated, tempCover: this.state.cover, saved: true});
            }
            else {
                this.setState({updated: updated, tempCover: this.state.cover, saved: true});
            }
            tempAlert("✓ Saved", "😃 💾 👍");
        }.bind(this)).catch(function(error) {
            errorLog("handleSubmit", error);
            tempAlert("⚠️ Failed", "Error occured while saving");
        });
    }

    handleStyleControls(isShow) {
        // if (isShow) {
        //     document.getElementById('styleControls').classList.remove('d-none');
        // }
        // else {
        //     document.getElementById('styleControls').classList.add('d-none');
        // }
    }

    render() {
        function timestampToISOString(timestamp) {
            var date = new Date(timestamp);
            return date.toISOString();
        }

        const theme = themeDecoder(this.props.theme);

        const wordNo = Math.floor(Math.random() * words.length);
        const placeholder = "\""+words[wordNo].words+"\"\n\n"+words[wordNo].by;

        console.log(this.state.pubImage);

        return (
            <div className={"theme "+theme}>
                <nav className="navbar fixed-top">
                    <Link className="btn btn-sm border-0" to="/me"><i className="fas fa-arrow-left"></i></Link>
                    <a className="navbar-brand mr-3" href="#">🅿 Paper</a>
                    {/* <div id="styleControls" className="d-none"> */}
                    <div id="styleControls" className="mr-auto">
                        <BlockStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleBlockType}
                            />
                        <InlineStyleControls
                            editorState={this.state.editorState}
                            onToggle={this.toggleInlineStyle}
                        />
                    </div>
                    {this.state.updated ?
                        <span id={this.state.key+"timeago"} className="nav-item timeago mr-2" data-saved={this.state.saved} title={timestampToISOString(this.state.updated)}></span>
                    :null}
                    <button type="button" className="btn btn-sm mr-1" onClick={this.handleSubmit}><i className="fas fa-save mr-1"></i> Save</button>
                    <button type="button" className={"btn btn-sm"+(this.state.publish ? "" : " btn-filled")} onClick={this.changePublish}><i className="fas fa-globe mr-1"></i> {this.state.publish ? "Published!" : "Publish"}</button>
                </nav>
                <div className="paper__editor container pb-5">
                    <form autoComplete="off">
                        <input id="key" name="key" type="hidden" value={this.state.key}/>
                        <div className="btn btn-sm paper__url mb-3 btn-block" onClick={() => copyLink('https://paper.coffee/@'+this.props.username+'/'+this.state.slug)}><i className="fas fa-link mr-2"></i>paper.coffee/@{this.props.username}/{this.state.slug}</div>
                        {this.state.cover ? 
                            <div className="paper__image mb-3">
                                <img src={this.state.cover}/>
                            </div>
                        :null}
                        {this.state.cover ? 
                            <div className="btn btn-sm mb-3" onClick={() => this.setState({cover: "", saved: false})}><i className="fas fa-trash mr-2"></i> Remove image</div>
                        :
                            <div className="mb-3">
                                <div className="btn btn-sm mr-2" onClick={() => document.getElementById('paper__file').click()}><i className="fas fa-camera"></i></div>
                                <div className="btn btn-sm" data-toggle="modal" data-target="#coverModal"><i className="fas fa-link"></i></div>
                                <div className="btn btn-sm border-0">Choose cover image</div>
                                <input id="paper__file" type="file" onChange={this.updateFile} />
                                {/* <input id="cover" name="cover" type="text" placeholder="https://yourdomain.com/coverimage.jpg"/> */}
                            </div>
                        }
                        {/* <i className="fas fa-image mr-2"></i><input className="mb-3" id="cover" name="cover" type="text" placeholder="https://yourdomain.com/coverimage.jpg" value={this.state.cover} onChange={this.handleChange}/> */}
                        <div className="py-3">
                            <input type="text" className="paper__title" id="title" name="title" aria-describedby="titleHelp" value={this.state.title} placeholder="Title" onChange={this.handleChange} />
                            {/* <small id="titleHelp" className="form-text text-muted">This field is required.</small> */}
                        </div>
                        <div className="paper__bar"></div>
                        {/* <div className="form-group"> */}
                            {/* <label htmlFor="tags">Tags</label>
                            <input type="text" className="form-control" id="tags" name="tags" value={this.state.tags} aria-describedby="tagsHelp" placeholder="separate tags by comma(,)" onChange={this.handleChange} />
                            <small id="tagsHelp" className="form-text text-muted">Tags will be used to sort and categorize notes.</small> */}
                        {/* </div> */}
                        <div className="RichEditor-editor paper__content pt-3 pb-5" onClick={this.focus}>
                            <Editor 
                                ref="editor"
                                onBlur={this.onBlur}
                                blockStyleFn={getBlockStyle}
                                customStyleMap={styleMap}
                                keyBindingFn={this.mapKeyToEditorCommand}
                                spellCheck={true}
                                editorState={this.state.editorState}
                                handleKeyCommand={(command)=>this.handleKeyCommand(command, false)}
                                placeholder={placeholder}
                                onChange={(editorState)=>this.onChange(editorState, false)}
                            />
                        </div>
                    
                        {/* <button type="submit" className="btn btn-primary mr-1"><i className="fas fa-save"></i> Save</button> */}
                        {/* <Link className="btn btn-light" to={this.state.key ? "/note/"+this.state.key : "/note/list"}><i className="fas fa-arrow-left"></i> Back</Link> */}
                    </form>
                </div>
                <div className="modal fade" id="coverModal" tabIndex="-1" role="dialog" aria-labelledby="coverModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content bg-transparent border-0">
                            {this.state.preview ? 
                            <div className="cover__preview mb-3" style={{backgroundImage: 'url("'+this.state.preview+'")'}}></div>
                            :null}
                            <input id="preview" className="mb-2" name="preview" type="text" placeholder="https://yourdomain.com/coverimage.jpg" value={this.state.preview} onChange={this.handleChange}/>
                            {this.state.preview ? 
                            <div className="btn btn-block btn-okay" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({cover: this.state.preview, preview: '', saved: false})}><i className="fas fa-check mr-2"></i>Use this image</div>
                            :null}
                            <div className="btn btn-block btn-cancel" data-dismiss="modal" aria-label="Close" onClick={() => this.setState({preview: ''})}><i className="fas fa-times mr-2"></i>Cancel</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
    // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    // fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
    },
};
function getBlockStyle(block) {
    switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
    }
}
class StyleButton extends React.Component {
    constructor() {
    super();
    this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
    };
    }
    render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
        className += ' RichEditor-activeButton';
    }
    return (
        <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
        </span>
    );
    }
}
const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H3', style: 'header-three'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
];
const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState.getSelection();
    const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
    return (
    <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) =>
        <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
        />
        )}
    </div>
    );
};
var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
];
const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return (
    <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
        <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
        />
        )}
    </div>
    );
};

export default Edit;

const words = [{
    words: "Words can be like X-rays if you use them properly -- they'll go through anything. You read and you're pierced.",
    by: "--Aldous Huxley, Brave New World"
},
{
    words: "A writer is someone for whom writing is more difficult than it is for other people.",
    by: "--Thomas Mann, Essays of Three Decades"
},
{
    words: "Let me live, love, and say it well in good sentences.",
    by: "--Sylvia Plath, The Unabridged Journals of Sylvia Plath"
},
{
    words: "Don't bend; don't water it down; don't try to make it logical; don't edit your own soul according to the fashion. Rather, follow your most intense obsessions mercilessly.",
    by: "--Franz Kafka"
},
{
    words: "I kept always two books in my pocket, one to read, one to write in.",
    by: "--Robert Louis Stevenson"
},
{
    words: "You can make anything by writing.",
    by: "--C.S. Lewis"
},
{
    words: "A word after a word after a word is power.",
    by: "--Margaret Atwood"
},
{
    words: "Tears are words that need to be written.",
    by: "--Paulo Coelho"
},
{
    words: "You should write because you love the shape of stories and sentences and the creation of different words on a page. Writing comes from reading, and reading is the finest teacher of how to write.",
    by: "--Annie Proulx"
},
{
    words: "To survive, you must tell stories.",
    by: "--Umberto Eco, The Island of the Day Before"
},
{
    words: "If my doctor told me I had only six minutes to live, I wouldn't brood. I'd type a little faster.",
    by: "--Isaac Asimov"
},
{
    words: "I write to discover what I know.",
    by: "--Flannery O'Connor"
},
{
    words: "Ideas are like rabbits. You get a couple and learn how to handle them, and pretty soon you have a dozen.",
    by: "--John Steinbeck"
},
{
    words: "Words do not express thoughts very well. They always become a little different immediately after they are expressed, a little distorted, a little foolish.",
    by: "--Hermann Hesse"
},
{
    words: "Find out the reason that commands you to write; see whether it has spread its roots into the very depth of your heart; confess to yourself you would have to die if you were forbidden to write.",
    by: "--Rainer Maria Rilke"
},
{
    words: "As a writer, you should not judge, you should understand.",
    by: "--Ernest Hemingway"
},
{
    words: "Writers live twice.",
    by: "--Natalie Goldberg"
},
{
    words: "Words are a lens to focus one's mind.",
    by: "--Ayn Rand"
},
{
    words: "I am irritated by my own writing. I am like a violinist whose ear is true, but whose fingers refuse to reproduce precisely the sound he hears within.",
    by: "--Gustave Flaubert"
},
{
    words: "Writing is its own reward.",
    by: "--Henry Miller"
},
{
    words: "A blank piece of paper is God's way of telling us how hard it is to be God.",
    by: "--Sidney Sheldon"
},
{
    words: "I went for years not finishing anything. Because, of course, when you finish something you can be judged.",
    by: "--Erica Jong"
},
{
    words: "When you make music or write or create, it's really your job to have mind-blowing, irresponsible, condomless sex with whatever idea it is you're writing about at the time. ",
    by: "--Lady Gaga"
}]