function themeDecoder(value) {
	switch (value) {
	  case 'Mono':
		return 'theme__mono';
		break;
	  case 'Mono--d':
		return 'theme__mono--dark';
		break;
	  case 'Spotify':
		return 'theme__spotify';
		break;
	  case 'Spotify--d':
		return 'theme__spotify--dark';
		break;
		case 'Berry':
		return 'theme__berry';
		break;
	  case 'Berry--d':
		return 'theme__berry--dark';
		break;
		case 'Peach':
		return 'theme__peach';
		break;
	  case 'Peach--d':
		return 'theme__peach--dark';
		break;
		case 'Cabin':
		return 'theme__cabin';
		break;
	  case 'Cabin--d':
		return 'theme__cabin--dark';
		break;
		case 'Mint':
		return 'theme__mint';
		break;
	  case 'Mint--d':
		return 'theme__mint--dark';
		break;
		case 'Mustard':
		return 'theme__mustard';
		break;
	  case 'Mustard--d':
		return 'theme__mustard--dark';
		break;
	  case 'Coffee--d':
		return 'theme__coffee--dark';
		break;
	  default:
		return 'theme__coffee';
		break;
	}
}

function timestampToDateString(timestamp) {
	var date = new Date(timestamp);
	return date.getFullYear() + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
}

export { themeDecoder, timestampToDateString }

