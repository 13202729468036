import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import Edit from './edit';
import AppTest from './apptest';
import Post from './post';
import Paper from './paper';
import QuickLink from './quickLink';
import Jimp from 'jimp';

import { fire, db, auth, googleSignin, userSignout, errorLog } from './fire';
import { tempAlert, copyLink, alphanumeric, usernamefy, httpChecker, firebaseChecker } from './helper';
import { themeDecoder, timestampToDateString } from './themes';
import NoMatch from './nomatch';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect
} from 'react-router-dom';

const Controller = () => (
  <Router>
      <div id="root__inner">
          <Switch>
              {/* <Route path="/:page(note)/:id/:type(edit)" component={App} /> */}
              {/* <Route path="/:page(note|folder)/:id" component={App} /> */}
              <Route path="/:page(edit|read)/:id" component={App} />
              <Route path="/:page(me|settings)" component={App} />
              <Route path="/discover" component={Discover} />
              <Route path="/terms" component={Terms} />
              {/* <Route path="/admin" component={Admin} /> */}
              {/* <Route path="/test" component={AppTest} /> */}
              <Route exact path="/" component={App} />
              <Route component={NoMatch}/>
          </Switch>
      </div>
  </Router>
);

export default Controller;

function generatePaper(username) {
  var name = '';

  if (auth.currentUser.displayName) {
    name = auth.currentUser.displayName;
  }

  var updates = {
    info: {
      username: username,
      author: name,
      title: 'Untitled',
      desc: '',
      theme: 'Coffee',
    },
    categories: [],
    posts: [],
    created: new Date().getTime()
  };

  db.ref('/papers/'+auth.currentUser.uid).update(updates);

  delete updates.created;

  return updates;
}

var temp = {};

class Discover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      papers: null,
      loading: true
    };
  }

  componentWillMount() {
    firebaseChecker();

    db.ref('/papers/').once('value', snapshot => {
      var papers = [];
      snapshot.forEach(function(childSnapshot) {
          const single = childSnapshot.val();
          papers.push(single);
      });
      this.setState({papers: papers, loading: false});
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loading align-items-center justify-content-center">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    var allPosts = [];
    if (this.state.papers) {
      this.state.papers.map(
        (item, i) => {
          if (item.posts) {
            const length = Object.keys(item.posts).length;
            // const title = item.info.title.trim().toLowerCase();
            if (length > 0 && item.info.title.toLowerCase() !== "untitled") {
              const posts = item.posts;
              for (var i in posts) {
                // if (item.title.trim().toLowerCase() !== "untitled" && !posts[i].title.trim().toLowerCase().includes("test") && posts[i].publish) {
                if (posts[i].title.trim().toLowerCase() !== "untitled" && !posts[i].title.trim().toLowerCase().includes("test") && posts[i].publish) {
                  posts[i]['info'] = item.info;
                  allPosts.push(posts[i]);
                }
              }
            }
          }
      });
    }

    if (allPosts.length > 0) {
      allPosts.sort(function (a, b) {
        return b.updated - a.updated;
      });
    }

    return (
      <div className="theme theme__coffee home">
        <nav className="navbar fixed-top">
          <Link className="navbar-brand mr-auto" to="/">🅿 Paper</Link>
          {auth.currentUser ? 
            <span>
              <a href="https://twitter.com/seoultonykim" target="_blank" className="btn btn-sm mr-1"><i className="fab fa-twitter mr-2"></i>Ask Tony</a>
              <button type="button" className="btn btn-sm" onClick={userSignout}>Logout</button>
            </span>
            :
            <button type="button" className="btn btn-sm ml-4 mr-1" onClick={googleSignin}>Login with Google</button>
          }
        </nav>
        <div className="container pb-5">
        <div className="mb-4 font-weight-bold">☕️ Some of the latest posts from Paper.Coffee</div>
        <div class="row">
          {
            // show only first 12 posts
            allPosts.slice(0, 12).map((item, i) =>
                <div className="col-md-4">
                  <a class="card mb-4 bg-transparent" href={"https://paper.coffee/@"+item.info.username+"/"+item.slug} target="_blank" key={i}>
                    <div class="card-body">
                      <h5 class="card-title">{item.title}</h5>
                      <p class="card-text">
                        {timestampToDateString(item.updated)}<br/>
                        by {item.info.author}
                      </p>
                    </div>
                  </a>
                </div>
            )
          }       
        </div>
        </div>
      </div>
    );
  }
}

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      papers: null,
      loading: true
    };
  }

  componentWillMount() {
    firebaseChecker();

    db.ref('/papers/').once('value', snapshot => {
      var papers = [];
      snapshot.forEach(function(childSnapshot) {
          const single = childSnapshot.val();
          papers.push(single);
      });
      this.setState({papers: papers, loading: false});
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loading align-items-center justify-content-center">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    var userCnt = 0;
    var postCnt = 0;

    return (
      <div style={{color: "white"}}>
        {this.state.papers ? this.state.papers.map(
          (item, i) => {
            if (item.posts) {
              const length = Object.keys(item.posts).length;
              const title = item.info.title.trim().toLowerCase();
              if (length > 1) {
                userCnt = userCnt+1;

                return <div><a href={"https://paper.coffee/@"+item.info.username} target="_blank">{item.info.title} | {item.info.author}</a></div>
                // postCnt = postCnt+1;
              }
            }
          }
        ):null}
        users : {userCnt}<br/>
        posts : {postCnt}
      </div>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loading: true,
      info: null,
      temp: null,
      posts: null,
      categories: null,
      page: 1
    };
    this.handleChange   = this.handleChange.bind(this);
    this.handleQlinkChange   = this.handleQlinkChange.bind(this);
    this.save = this.save.bind(this);
    this.setUsername = this.setUsername.bind(this);
    this.cancelSettings = this.cancelSettings.bind(this);
    this.loadMore = this.loadMore.bind(this);
  }

  componentWillMount() {
    auth.onAuthStateChanged(function(user) {
      this.setState({
        user: user, loading: false
      });
      if (user) {
        this.getData();
      }
      else {
        this.resetData();
      }
    }.bind(this));
  }

  resetData() {
    var update = {
      user: null,
      loading: false,
      info: null,
      temp: null,
      posts: null,
      categories: null,
      page: 1
    };
    this.setState(update);
    this.props.history.push('/');
  }

  getData() {
    db.ref('/papers/'+auth.currentUser.uid+'/info').once('value', snapshot => {
      if (snapshot.val()) {
        // paper found, retrieve
        var updates = snapshot.val();
        updates['currentname'] = updates.username;
        this.setState({info: updates});
      }
      else {
        // paper not found, generate
        this.tryUsername(true);
      }
    });

    // db.ref('/papers/'+auth.currentUser.uid+'/posts').orderByChild('publish').equalTo(true).once('value', snapshot => {
    //   var posts = [];
    //   snapshot.forEach(function(childSnapshot) {
    //       const post = childSnapshot.val();
    //       post['key'] = childSnapshot.key;
    //       posts.push(post);
    //   });

    //   if (posts.length > 0) {
    //     posts.sort(function (a, b) {
    //       return b.pubdate - a.pubdate;
    //     });
    //   }
    //   this.setState({posts: posts});
    // });

    const posts = db.ref('/papers/'+auth.currentUser.uid+'/posts');

    posts.on('child_added', snapshot => {
      var item = snapshot.val();
      item['key'] = snapshot.key;
      var newArray = [];
      if (this.state.posts) {
        newArray = [item].concat(this.state.posts);
      }
      else {
        newArray = [item];
      }

      this.setState({ posts: newArray });
    });

    posts.on('child_changed', snapshot => {
      var item = snapshot.val();
      item['key'] = snapshot.key;

      var newArray = [];
      newArray = this.state.posts.concat();

      for (var i in newArray) {
          if (newArray[i].key === snapshot.key) {
              newArray[i] = item;
              break;
          }
      }

      this.setState({ posts: newArray });
    });


    posts.on('child_removed', snapshot => {
      var newArray = this.state.posts.filter(function(item){return item.key !== snapshot.key});

      this.setState({ posts: newArray });
    });

    const categories = db.ref('/papers/'+auth.currentUser.uid+'/categories');

    categories.on('child_added', snapshot => {
      var item = snapshot.val();
      item['key'] = snapshot.key;
      var newArray = [];
      if (this.state.categories) {
        newArray = [item].concat(this.state.categories);
      }
      else {
        newArray = [item];
      }

      this.setState({ categories: newArray });
    });


    categories.on('child_changed', snapshot => {
      var item = snapshot.val();
      item['key'] = snapshot.key;

      var newArray = [];
      newArray = this.state.categories.concat();

      for (var i in newArray) {
          if (newArray[i].key === snapshot.key) {
              newArray[i] = item;
              break;
          }
      }

      this.setState({ categories: newArray });
    });


    categories.on('child_removed', snapshot => {
      var newArray = this.state.categories.filter(function(item){return item.key !== snapshot.key});

      this.setState({ categories: newArray });
    });
  }

  tryUsername(isFirst) {

    const displayName = auth.currentUser.displayName;
    var username = usernamefy(displayName);

    if (!isFirst && username !== '') {
      username = username+new Date().getTime();
    }
    else if (username === '') {
      username = 'paper'+new Date().getTime();
    }

    var updates = {};
    updates[username] = auth.currentUser.uid;
    
    db.ref('/usernames').update(updates).then(function(){
      this.setPaper(username);
    }.bind(this)).catch(function(error) {
      errorLog("tryUsername", error);
      this.tryUsername(false);
    });
  }

  setPaper(username) {
    var updates = generatePaper(username);
    // updates['user'] = auth.currentUser;
    updates['info']['currentname'] = username;
    this.setState(updates);
  }

  loadMore() {
    const page = this.state.page+1;
    this.setState({page: parseInt(page)});
  }


  handleChange(event) {
    const target = event.target;
    const name = target.name;
    var value = target.value;

    if (name === 'username') {
      const regExp = /[^a-z0-9_]/g;
      alphanumeric(target.value);
      value = target.value.toLowerCase().replace(regExp, "");
    }

    var info = Object.assign({}, this.state.info);
    info[name] = value;


    this.setState({info: info});
  }


  handleQlinkChange(e) {
    const target = e.target;
    const name = target.name;
    const value = httpChecker(target.value);

    const order = target.dataset.order;
    var info = Object.assign({}, this.state.info);

    if (!info.quicklinks) {
      info['quicklinks'] = ['','','','',''];
    }

    info['quicklinks'][order] = value;

    this.setState({info: info});
  }

  save() {
    if (!this.state.info.title) {
      tempAlert("⚠️ Oops", "Title is required");
      return;
    }

    if (!this.state.info.author) {
      tempAlert("⚠️ Oops", "Author name is required");
      return;
    }

    if (!this.state.info.username) {
      tempAlert("⚠️ Oops", "Username is required");
      return;
    }

    if (this.state.info.username.length < 4) {
      tempAlert("⚠️ Oops", "Username should be at least 4 characters long");
      // this.setState({isValid: false});
      // document.getElementById("username").focus();
      // document.getElementById("username-feedback").innerText = 'At least 4 characters long.';
      return;
    }

    if (this.state.info.username !== this.state.info.currentname) {
      this.setUsername(this.state.info.username, this.state.info.currentname);
    }
    else {
      var updates = Object.assign({}, this.state.info);
      delete updates.currentname;
  
      db.ref('/papers/'+auth.currentUser.uid+'/info').update(updates).then(function(){
        tempAlert("✓ Saved", "😃 💾 👍");
        // this.setState({isValid: true, saved: true});
        this.props.history.push('/me');
      }.bind(this)).catch(function(error) {
        errorLog("save", error);
        tempAlert("⚠️ Failed", "Error occured while saving");
      });
    }

  }

  setUsername(newname, current) {
    var updates = {};
    updates[newname] = auth.currentUser.uid;
    updates[current] = null;
    
    var info = Object.assign({}, this.state.info);
    delete info.currentname;
    
    db.ref('/usernames').update(updates).then(function(){
      info['currentname'] = newname;
      this.setState({info: info});
      this.save();
    }.bind(this)).catch(function(error) {
      tempAlert("⚠️ Failed", "Choose different username");
      // this.setState({isValid: false});
      // document.getElementById("username").focus();
      // document.getElementById("username-feedback").innerText = 'This username already exists.';
    }.bind(this));
  }

  createPost() {
    const posts = db.ref('/papers/'+auth.currentUser.uid+'/posts');
    const key = posts.push().key;

    const timestamp = new Date().getTime();
    var updates = {};
    updates['cover']    = '';
    updates['pubImage'] = '';
    updates['content']  = '';
    updates['pubdate']  = '';
    updates['updated']  = timestamp;
    updates['title']    = "Untitled";
    updates['slug']    = "untitled"+key;
    updates['publish']  = false;

    posts.child(key).update(updates).then(function(){
      // redirect
      window.location.href = "/edit/"+key;
    }.bind(this)).catch(function(error) {
      errorLog("createPost", error);
      tempAlert("⚠️ Failed", "Error occured while creating a new post");
    });
  }

  cancelSettings() {
    this.setState({info: temp});
    this.props.history.push('/me');
  }

  render() {
    const { id, page } = this.props.match.params;

    if (this.state.loading || (auth.currentUser && !this.state.info)) {
      return (
        <div className="loading align-items-center justify-content-center">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    else if (!auth.currentUser && page) {
      return <Redirect to={{pathname: "/"}} />
    }
    else if (!auth.currentUser) {
      return <Home />;
    }
    else if (auth.currentUser && !page) {
      return <Redirect to={{pathname: "/me"}} />
    }
    else if (id && this.state.posts) {
      var match;
      var prev;
      var next;

      const order = this.state.posts.findIndex(x => x.key === id);

      if (order >= 0) {
        match = this.state.posts[order];
        // .filter(function(item){return item.key === id});
      }

      if (order > 0) {
        prev = this.state.posts[order-1].key;
      }

      if (order < this.state.posts.length-1) {
        next = this.state.posts[order+1].key;
      }

      if (match) {
        if (page === 'edit') {
          return <Edit theme={this.state.info.theme} username={this.state.info.username} post={match} />;
        }
        else {
          return <Post preview={true} info={this.state.info} post={match} prev={prev} next={next} />;
        }
      }
      else {
        return <div>Post Not Found</div>;
      }
    }
    
    const theme = themeDecoder(this.state.info.theme);
    const dummyQlinks = ['','','','',''];

    if (page === 'settings') {

      if (!temp.title) {
        temp = this.state.info;
      }

      return (
        <div className={"theme "+theme}>
        <nav className="navbar fixed-top">
          <a className="navbar-brand mr-auto" href="#">🅿 Paper</a>
          <span>
            <a href="https://twitter.com/seoultonykim" target="_blank" className="btn btn-sm mr-1"><i className="fab fa-twitter mr-2"></i>Ask Tony</a>
            <button type="button" className="btn btn-sm" onClick={userSignout}>Logout</button>
          </span>
        </nav>
        <div className="paper__settings container">
          <select className="form-control" id="theme" name="theme" value={this.state.info.theme} onChange={this.handleChange}>
              <option value="Coffee">Coffee</option>
              <option value="Coffee--d">Coffee Dark</option>
              <option value="Mono">Mono</option>
              <option value="Mono--d">Mono Dark</option>
              <option value="Spotify">Spotify</option>
              <option value="Spotify--d">Spotify Dark</option>
              <option value="Berry">Berry</option>
              <option value="Berry--d">Berry Dark</option>
              <option value="Peach">Peach</option>
              <option value="Peach--d">Peach Dark</option>
              <option value="Cabin">Cabin</option>
              <option value="Cabin--d">Cabin Dark</option>
              <option value="Mint">Mint</option>
              <option value="Mint--d">Mint Dark</option>
              <option value="Mustard">Mustard</option>
              <option value="Mustard--d">Mustard Dark</option>
          </select>
          <input type="text" className="form-control" id="title" name="title" value={this.state.info.title} placeholder="Blog name" onChange={this.handleChange} />
          <input type="text" className="form-control" id="username" name="username" value={this.state.info.username} placeholder="paper.coffee/@yourname" onChange={this.handleChange} />
          <input type="text" className="form-control" id="author" name="author" value={this.state.info.author} placeholder="Author" onChange={this.handleChange} />
          <input type="text" className="form-control" id="desc" name="desc" value={this.state.info.desc} placeholder="Describe your paper" onChange={this.handleChange} />
          {dummyQlinks.map(
            (item, i) => <input type="text" className="form-control" data-order={i} name="qlink" value={this.state.info.quicklinks ? this.state.info.quicklinks[i] : ""} placeholder="https://yourlink.com" onChange={this.handleQlinkChange} />
          )}
          <button type="button" className="btn btn-sm mr-1" onClick={this.save}>Save</button>
          <button type="button" className="btn btn-sm" onClick={this.cancelSettings}>Cancel</button>
        </div>
        </div>
      );
    }

    if (temp.title) {
      temp = {};
    }

    // if (this.state.posts && this.state.info) {
    //   return <Paper info={this.state.info} posts={this.state.posts} />
    // }
    
    return (
      <div className={"theme "+theme}>
        <nav className="navbar fixed-top">
          <span>
            <a className="navbar-brand" href="#">🅿 Paper</a>
            <Link className="nav-item-bold" to="/discover">Discover</Link>
          </span>
          <span>
            <a href="https://twitter.com/seoultonykim" target="_blank" className="btn btn-sm mr-1"><i className="fab fa-twitter mr-2"></i>Ask Tony</a>
            <button type="button" className="btn btn-sm" onClick={userSignout}>Logout</button>
          </span>
        </nav>
        <div className="container">
          <div className="pb-2">
            <button type="button" className="btn btn-sm font-weight-bold mr-1" onClick={() => copyLink('https://paper.coffee/@'+this.state.info.username)}><i className="fas fa-link mr-1"></i>paper.coffee/@{this.state.info.username}</button>
            <Link className="btn btn-sm font-weight-bold mr-1" to="/settings"><i className="fas fa-cog"></i> Settings</Link>
            <button type="button" className="btn btn-sm btn-filled font-weight-bold" onClick={this.createPost}><i className="fas fa-plus mr-1"></i>New post</button>
          </div>
          <div className="paper__image">
          </div>
          <div className="paper__date pt-3">{this.state.info.author}</div>
          <div className="paper__title py-3">{this.state.info.title}</div>
          <div className="paper__content pb-3">{this.state.info.desc}</div>
          {this.state.info.quicklinks ? 
            <div className="paper__quickLinks pb-3">
              {this.state.info.quicklinks.map(
                (item, i) => {
                    if (item) return <QuickLink key={i} value={item}/>
                }
              )}
            </div>
          :null}
          <div className="pt-3">
          {this.state.posts ? this.state.posts.map(
              (item, i) => {
                if (i < this.state.page*10) return <ListItem key={i} item={item} />
              }
          ):null}
          {this.state.posts && this.state.posts.length > this.state.page*10 ?
              <button type="button" className="btn btn-sm btn-block" onClick={this.loadMore}>Load More</button>
          :null}
          </div>
        </div>
      </div>
    );
  }
}

const ListItem = (props) => {
  const { item } = props;

  function deleteItem() {
    db.ref('/papers/'+auth.currentUser.uid+'/posts').child(item.key).remove();
  }

  return (
    <div className="list-item">
      <Link to={"/edit/"+item.key} className="list-item-title text-truncate col">{item.title}</Link>
      <div className="list-item-buttons mt-2">
        {item.publish ?
        <span className="list-item-pub mr-auto"><i className="fas fa-globe mr-2"></i>Published</span>
        : 
        <span className="list-item-pub mr-auto">Draft</span>
        }
        {/* <Link to={"/edit/"+item.key} className="btn btn-sm border-0 mr-1"><i className="fas fa-pen-nib"></i> Edit</Link> */}
        <a className="btn btn-sm border-0" onClick={deleteItem}><i className="fas fa-trash"></i> Delete</a>
      </div>
    </div>
  );
}


// var obj = {"success":"You are welcome"};

// export default App;

const Home = (props) => {

  // 1 ~ 7
  const imgNo = Math.floor(Math.random() * 7)+1;

  return (
    <div className="theme theme__coffee home">
      <nav className="navbar fixed-top">
        <a className="navbar-brand mr-auto" href="#">🅿 Paper</a>
        <button type="button" className="btn btn-sm ml-4 mr-1" onClick={googleSignin}>Login with Google</button>
      </nav>
      <div className="container pb-5">
        <div className="paper__image">
          <img src={"/assets/"+imgNo+".jpg"}/>
        </div>
        <div className="paper__body">
          <div className="paper__title mb-3">Discover the pure joy of writing</div>
          <div className="paper__content mb-3">
            It's not always about writing for an audience.<br/>
            Everyone has a voice and yours is as valuable as the next.<br/>
            So write whatever comes to mind.<br/>
            Forget about using the right keywords and writing for the 'likes'.<br/>
            I hope <strong>Paper</strong> is more about you, not about them.<br/><br/>
            <strong>Be true to yourself and just write.</strong>
          </div>
          <button type="button" className="btn mt-3 mb-5" onClick={googleSignin}>Login with Google</button>
          <div className="pb-2">
            Any Questions?<a href="https://twitter.com/seoultonykim" target="_blank"><i className="fab fa-twitter ml-2 mr-1"></i>Ask Tony</a>
          </div>
          <span className="badge badge-dark">Beta</span> • Paper.coffee • <Link to="/terms">Terms</Link>
        </div>
      </div>
    </div>
  )
}

const Terms = (props) => {
  return (
    <div className="theme theme__coffee">
      <nav className="navbar fixed-top">
        <Link className="navbar-brand mr-auto" to="/">🅿 Paper</Link>
        <a href="https://twitter.com/seoultonykim" target="_blank" className="btn btn-sm"><i className="fab fa-twitter mr-2"></i>Ask Tony</a>
      </nav>
      <div className="container pb-5">
        <div className="paper__title mb-3">Terms & Privacy Policy</div>
        <div className="paper__content mb-3">
          <h4>Tracking</h4>
          <p>Paper.coffee uses Google Analytics to see the traffic.</p>
          <h4>When you signup...</h4>
          <p>
            Paper.coffee collects 2 things from Google when you signup.<br/><br/>
            <strong>1. Name</strong><br/>
            ONLY will be used for the default 'name' on your Paper.<br/><br/>
            <strong>2. Email</strong><br/>
            ONLY for to inform you when there is an update.<br/>
          </p>
          <h4>What you write...</h4>
          <p>Please don't 'publish' something stupid that could harm other people in common sense. It could lead to blocking your blog.</p>
        </div>
      </div>
    </div>
  )
}

function compressImage(path) {
  Jimp.read(path, (err, img) => {
    if (err) throw err;
    img
      .resize(700, Jimp.AUTO) // resize
      .quality(60) // set JPEG quality
      .greyscale() // set greyscale
      .getBuffer(Jimp.MIME_JPEG, (err, buffer)=>{
        //Transfer image file buffer to base64 string
        let base64Image = buffer.toString('base64');
        let imgSrcString = "data:" + Jimp.MIME_JPEG + ';base64, ' + base64Image;
        return imgSrcString
      }); 
  });
}

function addImage(image) {

  var div = document.querySelector('.paper__image');
  var img = new Image();
  // console.log(img);
  // img = image;
        
  img.onload = function() {
    div.appendChild(img);
  };
    
  img.src = image;
}
